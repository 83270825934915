import React from 'react'
import Select from 'react-select'
import QRCodeReact from 'qrcode.react'
import { ImagePicker } from './resourcepicker'

const options = [
  { value: 'none', label: 'Pas de validation' },
  { value: 'code', label: 'Mot code' },
  { value: 'qrcode', label: 'QR Code' },
  { value: 'image', label: 'Image en RA' }
]

const typeOptions = [
  { value: 'text', label: 'Texte' },
  { value: 'number', label: 'Nombre' }
]

export const PointValidation = ({ formData, name, onChange, schema, formContext }) => {
  return (
    <div>
      <div className="form-group field">
        <label className="control-label" htmlFor={name}>
          {schema.title}
        </label>
        <Select
          value={options.find(option => option.value === formData.type) || options[0]}
          onChange={option => onChange({ ...formData, type: option.value })}
          options={options}
          placeholder={'Sélectionnez une méthode de validation'}
          menuPlacement="bottom"
        />
      </div>
      {formData.type === 'code' && (
        <div className="form-group field field-string inline">
          <label htmlFor="textInput" className="control-label">
            Type de code
          </label>
          <Select
            value={typeOptions.find(option => option.value === formData.inputType) || typeOptions[0]}
            onChange={option => onChange({ ...formData, inputType: option.value })}
            options={typeOptions}
            menuPlacement="bottom"
          />
        </div>
      )}
      {['code', 'qrcode'].includes(formData.type) && (
        <>
          <div className="form-group field field-string inline">
            <label htmlFor="textInput" className="control-label">
              Code de validation
            </label>
            <input
              className="form-control"
              type={formData.inputType === 'number' ? 'number' : 'text'}
              id="textInput"
              value={formData.code || ''}
              onChange={event => onChange({ ...formData, code: event.target.value })}
            />
          </div>
          {formData.type === 'qrcode' && formData.code && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
              <QRCodeReact value={formData.code} size={128} level="M" />
            </div>
          )}
        </>
      )}

      {formData.type === 'image' && (
        <ImagePicker
          formData={formData.target}
          formContext={formContext}
          onChange={target => onChange({ ...formData, target })}
        />
      )}
    </div>
  )
}
